<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 pl-2 pr-4 pt-4 bg-accent">
            <div class="row px-2">

                <div class="col-lg-8">
                    <div class="toolbar mt-2">
                        <div class="btn-groupn" role="group" aria-label="Basic example">

                            <h3 class="vv mb-4">Manage Licences</h3>
                        </div>
                    </div>
                </div>
                <div class="col text-right d-none">
                    <!--router-link :to="'/d/licences/new'">
                        <button type="button" class="btn btn-primary d-inline">New Licence</button>

                    </router-link-->

                    <div class="dropdown">
                        <button class="btn btn-mid btn-primary dropdown-toggle d-inline   " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            New Licence
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#">Exclusive</a>
                            <a class="dropdown-item" href="#">Non Exclusive</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 bg-accent border-bottom">
            <div class="row">
                <div class="col-8">
                    <ul class="nav nav-tabs px-0 mx-0 border-0" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" @click.prevent="filterf('')">Uploaded</a>
                        </li>
                        <li class="nav-item d-none">
                            <a class="nav-link" id="meta-tab" data-toggle="tab" href="#customize" role="tab" @click.prevent="filterf('disabled')">Disabled</a>
                        </li>

                    </ul>
                </div>
                <div class="col d-none">
                    <div class="input-group  w-25n pr-3 pull-right float-right">
                        <div class="input-group-prepend ">
                            <div class="input-group-text">
                                <PhMagnifyingGlass class="text-muted" />
                            </div>
                        </div>
                        <input type="text" class="form-control " v-model="search_text" placeholder="Search licences...">

                    </div>
                </div>
            </div>

        </div>
        <div class="col-12 pt-4" v-show="ready">
            <ul class="list-group list-group-flush">
                <li class="list-group-item mb-0  py-4 " v-for="x in licences" :key="x.id">
                    <div class="row">
                        <div class="col-lg-8">
                            <router-link :to="'/d/licences/' + x.id">
                                <h4 class="px-0 text-capitalize"> {{x.name}}</h4>
                            </router-link>
                            <span>$ {{x.price}}</span>
                            <span class="px-2"></span>
                            <span class="tag ml-1 bg-primary text-white" v-if="x.exclusive" data-tooltip="exclusive licence">Exclusive</span>

                            <span class="tag ml-1" data-tooltip="Mp3 file always included">Mp3</span>
                            <span class="tag ml-1" data-tooltip="Wav file included" v-if="x.wav">Wav</span>
                            <span class="tag ml-1" data-tooltip="Stems included" v-if="x.stems">Stems</span>

                        </div>
                        <div class="col">
                            <span class="float-right mx-2">
                                <span class="control">
                                    <button class="btn btn-secondary btn-slim" @click="deletet(x)">
                                        <ph-trash-simple class="text-danger" />
                                    </button>
                                </span>
                            </span>
                            <router-link :to="'/d/licences/' + x.id"><span class="float-right">
                                    <span class="btn btn-secondary btn-slim">
                                        <ph-pencil-simple />
                                    </span> </span></router-link>

                        </div>
                    </div>

                </li>

            </ul>

        </div>
        <div class="col-lg-12 text-center pt-5 animate__animated animate__fadeIn" v-show="!ready">
            <span class="loading-lg  text-center mx-auto"></span>
            <br>
            <br>
            <span class="text-muted pt-5 mt-5">loading...</span>
        </div>
    </div>

    <div class="modal fade" id="new_licence" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header text-center ">
                    <h5 class="modal-title text-center" id="exampleModalLabel">
                        Create New Licence
                    </h5>
                    <i class="ph-x btn-close" data-dismiss="modal" aria-label="Close"></i>
                </div>
                <div class="modal-body">
                    <div class="row">
                        :to="'/d/licences/new'"
                        <div class="col-4 card card-link mx-2 p-4" @click="$router.push('/d/beats')">
                            <h5>lic namen</h5>
                            <span class="text-muted">$19.99</span>
                            <small class="text-right">Exclusive</small>
                        </div>
                    </div>
                </div>

                <!--div class="modal-footer pb-0 px-0 mx-0 ">
                                <div class="col-6 d-table btn-modal-left px-0 mx-0 my-0 " data-dismiss="modal">
                                    <span class="align-middle d-table-cell text-center">CANCEL</span>
                                </div>
                                <div class="col-6 d-table btn-modal-right mx-0 px-0 my-0 ">
                                    <span class="align-middle d-table-cell text-center"> <i class="ci-user"></i> SUBMIT</span>
                                </div>
                            </div-->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    PhMagnifyingGlass,
    PhFileX,
    PhX,
    PhClock,
    PhFileDotted,
    PhTrashSimple,
    PhPencilSimple,
    PhShareNetwork,
    PhPlus
} from "phosphor-vue";
export default {
    name: "Licences",
    components: {
        PhMagnifyingGlass,
        PhTrashSimple,
        PhPencilSimple,
    },
    data() {
        return {
            licences: [],
            colors: ['#50E3C2', '#12A5F5', '#2A2A72', '#5469d4', '#DEC1FF'],
            fbase3: "https://storage.googleapis.com/trillwave/image/300/",
            fbaseo: "https://storage.googleapis.com/trillwave/image/orig/",
            filter: "",
            search_text: "",
            ready: false,
        };
    },
    mounted() {
        var baseURI = this.$baseurl + "/licences";

        var config = {
            Authorization: "Bearer " + this.$store.getters.getUser.token
        };
        this.$http.get(baseURI, {
            headers: config
        }).then(result => {
            this.licences = result.data
            this.ready = true;
            //this.keys = Object.keys(this.res[0]);
        });

    },
    methods: {
        filterf(fltr) {
            this.search_text = ""
            this.filter = fltr
        },
    }

}
</script>

<style>

</style>
